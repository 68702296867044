import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_distribution_lists_dropdown = _resolveComponent("distribution-lists-dropdown")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, {
    headers: _ctx.headers,
    items: _ctx.items,
    "items-name": _ctx.itemsName,
    "show-selection": _ctx.showSelection
  }, {
    "body-cell-name-slot": _withCtx((props) => [
      _createVNode(_component_router_link, {
        to: { name: 'base.services.distributionLists.details', params: { id: props.row.id } },
        class: "d-flex align-center font-color-inherit font-weight-normal blue-on-hover"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_icon, {
            name: "tmi-email-send-direct",
            size: "xLarge",
            class: "mr-2 distinct--text"
          }),
          _createElementVNode("span", null, _toDisplayString(props.row.name), 1)
        ]),
        _: 2
      }, 1032, ["to"])
    ]),
    "body-cell-recipients-slot": _withCtx((props) => [
      _createElementVNode("span", null, _toDisplayString(props.row.recipients) + " " + _toDisplayString(props.row.recipients > 1 ? 'people' : 'person'), 1)
    ]),
    "body-cell-actions": _withCtx(() => [
      _createVNode(_component_distribution_lists_dropdown)
    ]),
    _: 1
  }, 8, ["headers", "items", "items-name", "show-selection"]))
}