
import { computed, defineComponent, ref } from 'vue'
import DistributionListsFilter from '@/components/pages/services/distributionLists/DistributionListsFilter.vue'
import DistributionListsTable from '@/components/pages/services/distributionLists/DistributionListsTable.vue'
import DistributionListsEmpty from '@/components/pages/services/distributionLists/DistributionListsEmpty.vue'
import { useModes } from '@/compositions/modes'
import { getTableData } from '@/services/tableService'
import type { TableHeaders } from '@/definitions/shared/types'
import type { DistributionList } from '@/definitions/services/allowedEmails/types'

export default defineComponent({
  components: {
    DistributionListsTable,
    DistributionListsFilter,
    DistributionListsEmpty,
  },
  setup() {
    const { isEmptyMode } = useModes()

    const search = ref('')
    const selected = ref<DistributionList[]>([])
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Name', value: 'name-slot' },
      { text: 'Email for sharing', value: 'email' },
      { text: 'Recipients', value: 'recipients-slot' },
    ])
    const tableItems = ref<DistributionList[]>(getTableData('distributionLists'))
    const filteredTableItems = computed<DistributionList[]>(() => {
      return tableItems.value.filter((item) => item.name.toLowerCase().includes(search.value.toLowerCase()))
    })

    return {
      isEmptyMode,
      search,
      selected,
      tableHeaders,
      filteredTableItems,
    }
  },
})
