import { useModals } from '@/compositions/modals'

type UseDistributionList = () => {
  openDeleteModal: (count: number) => void
}

const useDistributionList: UseDistributionList = () => {
  const { openModal } = useModals()

  const openDeleteModal = (count = 1) => {
    openModal('confirmation', {
      title: `Delete list${count > 1 ? 's' : ''}`,
      text: `You will no longer be able to forward SMS using ${count > 1 ? 'these' : 'this'}this distribution email address${count > 1 ? 'es' : ''}. Are you sure you want to delete ${count > 1 ? 'them' : 'it'}?`,
      btnText: 'Delete',
      btnColor: 'red',
    })
  }

  return {
    openDeleteModal,
  }
}

export default useDistributionList
